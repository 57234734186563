import React, { useState } from "react";
import firebase from "gatsby-plugin-firebase";
import { Button, Modal } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { Select } from "../components/Core";
import "./affiliate-contact.css"
import "./custom-header-bg.css"

const defaultCategories = [
  { value: "Agriculture", label: "Agriculture" },
  { value: "Beverages", label: "Beverages" },
  { value: "Foodstuff", label: "Foodstuff" },
  { value: "Handicrafts", label: "Handicrafts" },
  { value: "Manufactured Goods", label: "Manufactured Goods" },
  { value: "Nature Goods", label: "Nature Goods" }
];

const Contact = () => {
  const emptyData = {
    YourName: "",
    LinkedinProfile: "",
    YourIsd: "",
    YourPhone: "",
    Email: "",
    YourOrg: "",
    YourOrgWebsite: "",
    ProdDesc: "",
    Address: "",
    Street: "",
    City: "",
    Country: "",
    Message: "",
  }
  const [industrySelected, setIndustrySelected] = useState({ value: 'Beverages', label: 'Beverages' });

  const [data, setData] = useState(emptyData)
  const [show, setModalShow] = useState(false);

  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

  const handleChange = e =>
    setData({ ...data, [e.target.name]: e.target.value })

  const handleCategoriesChange = (selectedOptions) => {
    setIndustrySelected(selectedOptions);
  }

  const handleProductDescription = e =>
    setData({ ...data, [e.target.name]: e.target.value })

  const handleSubmit = (e) => {
    //console.log('handleSubmit')
    if (typeof window !== `undefined`) {
      e.preventDefault();
      //console.log(data);

      let myForm = document.getElementById("affiliate-contact");
      let formData = new FormData(myForm);
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          console.log("Form successfully submitted to netlify");
          data["Categories"] = industrySelected["label"];
          data["created"] = firebase.firestore.Timestamp.now();
          data["status"] = "applied";
          firebase
            .firestore()
            .collection('master-contacts')
            .add({ email: data["Email"], type: "affiliate-contact", created: data["created"] })
            .then(() => {
              console.log("master-contacts: Form successfully saved in firestore")
            })
            .catch((error) => {
              console.error("Error adding document to master-contacts: ", error);
            });
          firebase
            .firestore()
            .collection('affiliate-contacts')
            .add(data)
            .then(() => {
              console.log("affiliate-contacts: Form successfully saved in firestore")
            })
            .catch((error) => {
              console.error("Error adding document to affiliate-contacts: ", error);
            });
          setData(emptyData);
          setModalShow(true);
        })
        .catch((error) => alert(error));
    }
  };

  return (
    <>
      <PageWrapper>
        <div className="custom-header-background pt-16 pb-12 pt-lg-22 pb-lg-27">
          <div className="container">
              <h2 className="font-size-9 text-center mt-8 mb-11 text-white">Partner Contact Form</h2>
            <div className="row justify-content-center mt-14">
              <div className="mb-8">
                <p className="text-default-color font-size-4 px-5 text-white"></p>
                <p className="text-default-color font-size-4 px-5 text-white"></p>
              </div>
              <div className="col-xxl-6 col-xl-7 col-lg-8">
                <div className="bg-white px-9 pt-9 pb-7 shadow-8 rounded-4">
                  <form
                    id="affiliate-contact"
                    name="affiliate-contact"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={handleSubmit}
                  >
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="affiliate-contact" />
                    <div className="row">
                      <div className="col-6 mb-7">
                        <label
                          htmlFor="YourName"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          Your Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Your Name"
                          id="YourName"
                          name="YourName"
                          value={data.YourName}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-6 mb-7">
                        <label
                          htmlFor="LinkedinProfile"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          LinkedIn Profile
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="LinkedIn Profile"
                          id="LinkedinProfile"
                          name="LinkedinProfile"
                          value={data.LinkedinProfile}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="col-lg-6 mb-7">
                        <label
                          htmlFor="YourPhone"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          Your Phone
                        </label>
                        <br />
                        <input
                          type="text"
                          className="form-control"
                          placeholder="###"
                          id="YourIsd"
                          name="YourIsd"
                          value={data.YourIsd}
                          onChange={handleChange}
                          style={{ float: "left", width: "25%" }}
                          required
                        />
                        <span style={{ float: "left", width: "5%", textAlign: "center", paddingTop: "0.75rem" }}> - </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Phone Number"
                          id="YourPhone"
                          name="YourPhone"
                          value={data.YourPhone}
                          onChange={handleChange}
                          style={{ float: "left", width: "70%" }}
                          required
                        />
                      </div>
                      <div className="col-lg-6 mb-7">
                        <label
                          htmlFor="Email"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          E-mail
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="example@gmail.com"
                          id="Email"
                          name="Email"
                          value={data.Email}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="col-6 mb-7">
                        <label
                          htmlFor="YourOrg"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          Organization Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="If Representing an Organization"
                          id="YourOrg"
                          name="YourOrg"
                          value={data.YourOrg}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-6 mb-7">
                        <label
                          htmlFor="YourOrgWebsite"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          Organization Website
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Organization Website"
                          id="YourOrgWebsite"
                          name="YourOrgWebsite"
                          value={data.YourOrgWebsite}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="col-12 mb-7 form-group position-relative">
                        <label
                          htmlFor="Categories"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                          style={{ width: "100%" }}
                        >
                          Product Category
                        </label>
                        <Select
                          options={defaultCategories}
                          className="arrow-3 font-size-4 d-flex align-items-center w-100"
                          border={true}
                          id="Categories"
                          name="Categories"
                          onChange={handleCategoriesChange}
                          required
                        />
                      </div>

                      <div className="col-lg-12 mb-7">
                        <label
                          htmlFor="Proddesc"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                          style={{ width: "100%" }}
                        >
                          Product Description
                        </label>
                        <textarea
                          id="Proddesc"
                          placeholder="Type your description"
                          className="form-control h-px-144"
                          name="Proddesc"
                          value={data.proddesc}
                          onChange={handleProductDescription}
                          required
                        ></textarea>
                      </div>

                      <div className="col-lg-6 mb-7">
                        <label
                          htmlFor="Address"
                          className="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset"
                        >
                          Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Address"
                          id="Address"
                          name="Address"
                          value={data.Address}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-lg-6 mb-7">
                        <label
                          htmlFor="Street"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          Street
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Street"
                          id="Street"
                          name="Street"
                          value={data.Street}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="col-lg-6 mb-7">
                        <label
                          htmlFor="City"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          City
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="City"
                          id="City"
                          name="City"
                          value={data.City}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-lg-6 mb-7">
                        <label
                          htmlFor="Country"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          Country
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Country"
                          id="Country"
                          name="Country"
                          value={data.Country}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-lg-12 mb-7">
                        <label
                          htmlFor="message"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                          style={{ width: "100%" }}
                        >
                          Message
                        </label>
                        <textarea
                          id="Message"
                          placeholder="Type your message"
                          className="form-control h-px-144"
                          name="Message"
                          value={data.Message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </div>
                      <div className="col-lg-12 pt-4">
                        <button
                          type="submit"
                          className="btn btn-orange text-uppercase w-100 h-px-48"
                        >
                          Send Now
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Thank you!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Your form submission has been received.</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </PageWrapper>
    </>
  );
};
export default Contact;
